import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {GalleryStore} from './GalleryStore';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {IGalleryStyleParams} from '../types/galleryTypes';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_SETTINGS_CDN} from '@wix/wixstores-client-core/dist/es/src/constants';
import {Experiments} from '../constants';
import _ from 'lodash';

export class GalleryControllerFlowEditor extends BaseController {
  private readonly compId: string;
  private galleryStore: GalleryStore;
  private mainCollectionId: string = null;
  private readonly waitUntilReady: Promise<void>;
  private onReadyResolver;

  private async reloadWithCollection(collectionId): Promise<void> {
    this.galleryStore.setMainCollection(collectionId);
    await this.galleryStore.setInitialState();
    return Promise.resolve();
  }

  public exports = () => {
    return {
      setCollection: (collectionId: string): Promise<void> => {
        if (this.siteStore.experiments.enabled(Experiments.SetGalleryCollectionVelo)) {
          this.mainCollectionId = collectionId;
          if (this.galleryStore) {
            return this.waitUntilReady.then(() => this.reloadWithCollection(collectionId));
          } else {
            return this.waitUntilReady;
          }
        } else {
          return Promise.resolve();
        }
      },
    };
  };

  public onStyleUpdate = async (newStyleParams: IGalleryStyleParams) => {
    this.styleParams = newStyleParams;
    await this.galleryStore.updateState(newStyleParams, {APP: {}, COMPONENT: {}});
  };

  //todo(flow-editor): probably not an empty array
  public getFreeTexts = (): string[] => {
    return [];
  };

  private styleParams: any;
  private readonly config: ControllerParams['controllerConfig']['config'];
  private readonly componentId: string;

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.styleParams = controllerParams.controllerConfig.config.style.styleParams;
    this.config = _.clone(controllerParams.controllerConfig.config);
    this.compId = controllerParams.controllerConfig.compId;
    this.componentId = controllerParams.controllerConfig.type;
    const isEditor = typeof window !== 'undefined' && window.Wix;

    /* istanbul ignore else: todo(flow-editor): test? */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }

    this.waitUntilReady = new Promise((resolve) => {
      this.onReadyResolver = resolve;
    });
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.galleryStore.updateState(this.styleParams, {APP: undefined, COMPONENT: {}, appSettings: pb});
    });
  }

  public readonly load = async () => {
    const styleParamsWithDefaults = getStyleParamsWithDefaults(this.styleParams, () =>
      getStyleParamsWithDefaultsFunc({style: {styleParams: this.styleParams}, dimensions: undefined})
    );

    this.galleryStore = new GalleryStore(
      styleParamsWithDefaults,
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.compId,
      this.componentId,
      this.mainCollectionId,
      this.flowAPI.reportError,
      true
    );
    return this.galleryStore
      .setInitialState()
      .then(() => {
        if (this.siteStore.experiments.enabled(Experiments.SetGalleryCollectionVelo)) {
          this.onReadyResolver();
        }
      })
      .catch(this.flowAPI.reportError);
  };

  public readonly init = async () => {
    await this.load();
  };
}
